/* eslint-disable no-async-promise-executor */
import { WalletContextState, useWallet } from '@solana/wallet-adapter-react'
import { Token } from 'config/types'
import { useSigner } from 'packages/wagmi/hooks/useSigner'
import { useCallback, useMemo, useState } from 'react'
import { useAppSelector } from 'state'
import { deposit as depositEvmToken } from 'utils/infura'
import { deposit as depositSolToken } from 'utils/solana'
import { isSolToken } from 'utils/token'
import { isReject } from 'utils/wallet'
import { logError } from 'utils/sentry'
import { useAnalytics } from './useAnalytics'

function useDeposit(token: Token) {
  const signer = useSigner()

  const [submiting, setSubmiting] = useState(false)
  const solanaWallet: WalletContextState = useWallet()
  const { recordEvent } = useAnalytics()

  const tokenInfoes = useAppSelector((state) => state.app.tokenInfoes)
  const tokenDepositInfo = useMemo(() => {
    if (!token) return null

    const spender = tokenInfoes.find(
      (tokenInfo) => tokenInfo.network === token.network && tokenInfo.token === token.code,
    )?.contractAddress

    if (!spender) return null

    return {
      spender,
      token,
    }
  }, [token])

  const depositEvm = useCallback(
    async (value: string) => {
      const hash = await depositEvmToken(signer, tokenDepositInfo.token, value, tokenDepositInfo.spender)
      return hash
    },
    [tokenDepositInfo, signer],
  )

  const depositSolana = useCallback(
    async (value: string) => {
      const hash = await depositSolToken(tokenDepositInfo.token, value, solanaWallet)
      return hash
    },
    [tokenDepositInfo, solanaWallet],
  )

  const deposit = useCallback(
    async (value: string): Promise<string | null> =>
      new Promise(async (resolve) => {
        try {
          const depositFn = isSolToken(tokenDepositInfo.token) ? depositSolana : depositEvm
          setSubmiting(true)
          const hash = await depositFn(value)
          setSubmiting(false)

          if (hash) {
            recordEvent('deposit-success', {})
          } else {
            logError('deposit failed no hash', {
              extra: {
                response: 'no deposit hash',
                payload: {
                  token: token?.code,
                  chainId: token?.network,
                },
              },

              tags: ['deposit_failed_receipt'],
            })

            recordEvent('deposit-failure-no-hash', {})
          }

          resolve(hash)
        } catch (e: any) {
          resolve(null)
          setSubmiting(false)

          logError('deposit failed', {
            extra: {
              response: e?.message,
              payload: {
                token: token?.code,
                chainId: token?.network,
              },
            },
            tags: ['deposit_failed_receipt'],
          })

          if (isReject(e)) {
            recordEvent('deposit-reject', {})
          } else {
            recordEvent('deposit-failure', {})
          }
        }
      }),
    [solanaWallet, depositEvm],
  )

  return {
    deposit,
    submiting,
  }
}

export default useDeposit
