/* eslint-disable no-template-curly-in-string */
import BigNumber from 'bignumber.js'
import GameListModal from 'components/GameListModal/GameListModal'
import { Token } from 'config/types'
import {
  ApiBonusTypeEnums,
  BonusStatusEnums,
  BonusUserTypeEnums,
  BoosterWagerCommonBonus,
  CashCommonBonus,
  CommonBonus,
  DepositCashCommonBonus,
  DepositCommonBonus,
  DepositFreeSpinCommonBonus,
  FreeHUSDLockCommonBonus,
  FreeLuckyspinCommonBonus,
  FreeSpinCommonBonus,
  HUSDUnLockBoosterCommonBonus,
  ICommonBonus,
  LevelUpCommonBonus,
  WageringBonusStatusEnums,
} from 'config/types/bonus'
import {
  BoosterWagerBonus,
  CashBonus,
  DepositBonus,
  DepositCashBonus,
  DepositFreespinBonus,
  FreeHUSDLockBonus,
  FreeLuckyspinBonus,
  FreespinBonus,
  HUSDUnLockBoosterBonus,
  LevelUpBonus,
  UserBonus,
  WageringConditionBonus,
} from 'config/types/bonus/userBonus'
import { useAnalytics } from 'hooks/useAnalytics'
import useModal from 'hooks/useModal'
import { useRequest } from 'hooks/useRequest'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import BonusService from 'services/BonusService'
import GameService from 'services/GameService'
import { useAppSelector } from 'state'
import { useActiveBoosterWagerBonus, useActiveHUSDBoosterBonus, useActiveLevelUpBonus } from 'state/bonus/hooks'
import { useUserWager } from 'state/profile/hooks'
import { buildPluralizeText, delayed, revalidateHunnyLevel } from 'utils'
import { formatDisplayDate } from 'utils/dateHelper'
import { forkjoinRequest } from 'utils/requestHelper'

export enum ValidatedBonusStatusEnums {
  Claimed,
  Available,
  Expired,
  Used,
  HasOtherActivated,
  UserLevel,
  UserType,
  Cancelled,
  Unknown,
  Finish,
}

export const useFreespinGamesModal = (bonus) => {
  const [presentModalChooseGame, onDimiss] = useModal(GameListModal)
  const { t } = useTranslation()
  const request = useCallback(
    (offset: number, limit: number) => {
      return GameService.getGames({ gameIds: bonus.spinGameIds }, offset, limit)
    },
    [bonus],
  )

  const handlePresentModal = (playWithFiatCode?: Token) => {
    presentModalChooseGame({
      request,
      titleHeader: t('Choose Game'),
      contentDescription: t('Please choose a desirable game to play all your Free Spins'),
      playWithFiatCode,
    })
  }

  return [handlePresentModal, onDimiss]
}

export const useValidateBonus = (bonus: ICommonBonus) => {
  const activeLevelUpBonus = useActiveLevelUpBonus()
  const activeHUSDBoosterBonus = useActiveHUSDBoosterBonus()
  const activeBoosterWagerBonus = useActiveBoosterWagerBonus()
  const { score } = useUserWager()
  const userTier = useAppSelector((state) => state.profile.tier)

  const bigScore = useMemo(() => new BigNumber(score), [score])

  return useMemo(() => {
    if (bonus instanceof UserBonus) {
      if (bonus.status === BonusStatusEnums.Used) {
        if (bonus instanceof WageringConditionBonus && bonus.wageringBonusStatus === WageringBonusStatusEnums.Claimed) {
          return ValidatedBonusStatusEnums.Claimed
        }

        if (bonus instanceof WageringConditionBonus && bonus.wageringBonusStatus === WageringBonusStatusEnums.Finish) {
          return ValidatedBonusStatusEnums.Finish
        }

        if (
          bonus.IsCancelled ||
          (bonus instanceof WageringConditionBonus && bonus.wageringBonusStatus === WageringBonusStatusEnums.Cancelled)
        ) {
          return ValidatedBonusStatusEnums.Cancelled
        }

        return ValidatedBonusStatusEnums.Used
      }

      if (bonus.condition.isExpired) return ValidatedBonusStatusEnums.Expired

      if (!bonus.condition.validateUserLevelEligibility(userTier?.level) && bonus.status !== BonusStatusEnums.Active)
        return ValidatedBonusStatusEnums.UserLevel

      if (
        bigScore.gt(0) &&
        bonus.condition.userType === BonusUserTypeEnums.NewUser &&
        bonus.status !== BonusStatusEnums.Active
      )
        return ValidatedBonusStatusEnums.UserType

      if (bonus instanceof LevelUpBonus && activeLevelUpBonus) return ValidatedBonusStatusEnums.HasOtherActivated

      if (bonus instanceof HUSDUnLockBoosterBonus && activeHUSDBoosterBonus)
        return ValidatedBonusStatusEnums.HasOtherActivated

      if (bonus instanceof BoosterWagerBonus && activeBoosterWagerBonus)
        return ValidatedBonusStatusEnums.HasOtherActivated

      return ValidatedBonusStatusEnums.Available
    }
    return ValidatedBonusStatusEnums.Unknown
  }, [
    bonus,
    activeLevelUpBonus,
    activeHUSDBoosterBonus,
    userTier,
    (bonus as any)?.status,
    (bonus as any)?.wageringBonusStatus,
    bigScore.gt(0),
  ])
}

export const buildBonusName = (t: (content: string, config?: any) => string, bonus: any): [string, string] => {
  if (!bonus) return ['', '']

  if (bonus instanceof LevelUpBonus || bonus instanceof LevelUpCommonBonus) {
    return [
      t(
        buildPluralizeText('Increase {{extraLevel}} VIP {{level}} for {{time}} {{day}}', [
          { number: bonus.extraLevels, key: 'level', word: ['level', 'levels'] },
          { number: bonus.duration, key: 'day', word: ['day', 'days'] },
        ]),
        { extraLevel: bonus.extraLevels, time: bonus.duration },
      ),
      t('VIP upgrade'),
    ]
  }

  if (bonus instanceof HUSDUnLockBoosterBonus || bonus instanceof HUSDUnLockBoosterCommonBonus) {
    return [
      t(
        buildPluralizeText('HUSD unlock bonus +{{extraUnlockPercent}}% for {time} {{day}}', [
          { number: bonus.duration, key: 'day', word: ['day', 'days'] },
        ]),
        {
          extraUnlockPercent: bonus.extraUnlockPercent,
          time: bonus.duration,
        },
      ),
      t('HUSD unlock booster'),
    ]
  }

  if (bonus instanceof FreeLuckyspinBonus || bonus instanceof FreeLuckyspinCommonBonus) {
    return [
      t(
        buildPluralizeText('Receive {{amount}} lucky {{spin}}', [
          { number: bonus.amount, key: 'spin', word: ['spin', 'spins'] },
        ]),
        { amount: bonus.amount },
      ),
      t('Free lucky spin'),
    ]
  }

  if (bonus instanceof FreeHUSDLockBonus || bonus instanceof FreeHUSDLockCommonBonus) {
    return [t('Receive {{amount}} locked HUSD', { amount: bonus.amount }), t('HUSD bonus')]
  }

  if (bonus instanceof DepositBonus || bonus instanceof DepositCommonBonus) {
    return [t('{{percent}} on the deposit', { percent: `${bonus.percent}%` }), t('Deposit bonus')]
  }

  if (bonus instanceof FreespinBonus || bonus instanceof FreeSpinCommonBonus) {
    return [t('{{amount}} free spins', { amount: bonus.freeSpinAmount }), t('Freespin bonus')]
  }

  if (bonus instanceof DepositFreespinBonus || bonus instanceof DepositFreeSpinCommonBonus) {
    return [t('{{amount}} free spins', { amount: bonus.freeSpinAmount }), t('Freespin bonus')]
  }

  if (bonus instanceof CashBonus || bonus instanceof CashCommonBonus) {
    return [
      t('Receive {{amount}} {{currency}}', {
        amount: bonus.bonusAmount?.amount.toString(),
        currency: bonus.bonusAmount?.token?.code,
      }),
      t('{{currency}} bonus', {
        currency: bonus.bonusAmount?.token?.code,
      }),
    ]
  }

  if (bonus instanceof DepositCashBonus || bonus instanceof DepositCashCommonBonus) {
    return [
      t('Receive {{amount}} {{currency}} on Deposit', {
        amount: bonus.bonusAmount?.amount.toString(),
        currency: bonus.bonusAmount?.token?.code,
      }),
      t('{{currency}} bonus', {
        currency: bonus.bonusAmount?.token?.code,
      }),
    ]
  }

  if (bonus instanceof BoosterWagerBonus || bonus instanceof BoosterWagerCommonBonus) {
    return [t('{{amount}}% wager booster', { amount: bonus.boostPercent }), t('Boost Wager Bonus')]
  }

  return ['', '']
}

export const buildBonusNameAmount = (t: (content: string, config?: any) => string, bonus: any): [string, string] => {
  if (!bonus) return ['', '']

  if (bonus instanceof LevelUpBonus || bonus instanceof LevelUpCommonBonus) {
    return [
      t(buildPluralizeText('VIP {{level}}', [{ number: bonus.extraLevels, key: 'level', word: ['level', 'levels'] }])),
      `+${bonus.extraLevels || 0}`,
    ]
  }

  if (bonus instanceof HUSDUnLockBoosterBonus || bonus instanceof HUSDUnLockBoosterCommonBonus) {
    return [t('wager booster'), `${bonus.extraUnlockPercent || 0}%`]
  }

  if (bonus instanceof FreeLuckyspinBonus || bonus instanceof FreeLuckyspinCommonBonus) {
    return [
      t(buildPluralizeText('lucky {{spin}}', [{ number: bonus.amount, key: 'spin', word: ['spin', 'spins'] }])),
      `${bonus?.amount || 0}`,
    ]
  }

  if (bonus instanceof FreeHUSDLockBonus || bonus instanceof FreeHUSDLockCommonBonus) {
    return [t('HUSD'), `${bonus.amount || 0}`]
  }

  if (bonus instanceof DepositBonus || bonus instanceof DepositCommonBonus) {
    return [t('on the deposit'), `${(bonus?.percent || 0).toString()}%`]
  }

  if (
    bonus instanceof FreespinBonus ||
    bonus instanceof FreeSpinCommonBonus ||
    bonus instanceof DepositFreespinBonus ||
    bonus instanceof DepositFreeSpinCommonBonus
  ) {
    return [
      t(buildPluralizeText('free {{spin}}', [{ number: bonus.freeSpinAmount, key: 'spin', word: ['spin', 'spins'] }])),
      `${bonus?.freeSpinAmount || 0}`,
    ]
  }

  if (bonus instanceof CashBonus || bonus instanceof CashCommonBonus) {
    return [
      t('{{currency}}', {
        currency: bonus.bonusAmount?.token?.code,
      }),
      `${(bonus.bonusAmount?.amount || 0).toString()}`,
    ]
  }

  if (bonus instanceof DepositCashBonus || bonus instanceof DepositCashCommonBonus) {
    return [
      t('{{currency}}', {
        currency: bonus.bonusAmount?.token?.code,
      }),
      `${(bonus.bonusAmount?.amount || 0).toString()}`,
    ]
  }

  if (bonus instanceof BoosterWagerBonus || bonus instanceof BoosterWagerCommonBonus) {
    return [t('wager booster'), `${(bonus.boostPercent || 0).toString()}%`]
  }

  return ['', '']
}

export const useDisplayBonusName = (bonus: ICommonBonus): [string, string] => {
  const { t } = useTranslation()
  return useMemo(() => {
    return buildBonusName(t, bonus)
  }, [bonus, t])
}

export const getBonusErrorMessage = (t: TFunction<'translation', undefined>, bonus: CommonBonus, error: string) => {
  switch (error) {
    case 'voucher_max_claimed':
      return t('The bonus has been fully claimed!')
    case 'voucher_user_exists':
      return t('You have already used this bonus.')
    case 'user_type_new':
      return t("This bonus is only applicable to new users who haven't placed any bets yet.")
    case 'user_signup_after_time':
      return t('This bonus is only applicable to users who signed up after {{date}}.', {
        date: formatDisplayDate(new Date(bonus.condition.signUpAfter)),
      })
    case 'user_tier':
      const minTier = bonus.condition.minUserLevel
      const maxTier = bonus.condition.maxUserLevel
      if (minTier === maxTier) {
        return t('This bonus is only applicable to users with VIP level {{vipLevel}}.', {
          vipLevel: revalidateHunnyLevel(maxTier),
        })
      }
      return t('This bonus is only applicable to users with VIP levels between {{minTier}} - {{maxTier}}.', {
        minTier: revalidateHunnyLevel(minTier),
        maxTier: revalidateHunnyLevel(maxTier),
      })
    case 'wager_condition':
      return t(
        buildPluralizeText(
          bonus.condition.gameRequiredID
            ? 'You must have wagered a minimum of ${{amountWagered}} within the last {{amountDay}} {{day}} on the required game.'
            : 'You must have wagered a minimum of ${{amountWagered}} within the last {{amountDay}} {{day}} to use this bonus.',
          [{ number: bonus.condition.minAmountWagerInDay, key: 'day', word: ['day', 'days'] }],
        ),
        {
          amountWagered: bonus.condition.minAmountWagerToRedeem,
          amountDay: bonus.condition.minAmountWagerInDay,
        },
      )
    case 'voucher_inactive':
      return t('This bonus is invalid.')
    case 'voucher_expired':
      return t('This bonus has been expired.')
    case 'balance_condition':
      return t('You need a minimum balance of ${{amount}} to use this bonus.', {
        amount: bonus.condition.minBalanceToRedeem,
      })
    case 'user_claimed_in_group':
      return t('You have already redeemed a similar bonus.')

    default:
      return t("You don't meet the conditions of the promotion!")
  }
}

export const useRedeemBonus = () => {
  const { t } = useTranslation()
  const { recordEvent } = useAnalytics()
  const redeem = async (code: string, delay = 700): Promise<string | null> => {
    const [response] = await forkjoinRequest([BonusService.redeem(code.trim().toUpperCase()), delayed(delay)])

    const error = response?.data?.error
    const bonus: CommonBonus = response?.data?.bonus
    if (error || !response || !bonus) {
      return getBonusErrorMessage(t, bonus, error)
    }

    recordEvent('redeem_bonus', {
      isClaimToPlayBonus: (bonus as any)?.isCreaditReward,
      type: ApiBonusTypeEnums[bonus.type],
    })

    return null
  }

  return redeem
}

export const useBonusLogic = (bonus: UserBonus) => {
  const [bonusInstance, setBonusInstance] = useState({ instance: bonus })
  const { execute } = useRequest()

  useEffect(() => {
    setBonusInstance({ instance: bonus })
  }, [bonus])

  const handleBonusUsed = useCallback(async (useSuccessfully: boolean) => {
    if (useSuccessfully) return
    const newBonus = await execute(BonusService.getUserBonusDetails(bonus.id))
    if (newBonus.data) {
      setBonusInstance({ instance: newBonus.data })
    }
  }, [])

  const updateBonusInstance = useCallback((bonus: UserBonus) => {
    setBonusInstance({ instance: bonus })
  }, [])

  return { handleBonusUsed, updateBonusInstance, bonusInstance: bonusInstance.instance }
}
