export enum GamePlayMode {
  NORMAL = 10,
}

export enum GameMerchantCode {
  INHOUSE = 'INHOUSE',
}

export enum GameType {
  Casino = 1,
  Sport = 10,
  Casual = 11,
  Slot = 20,
  Lobby = 100,
  InHouse = 999,
}

export enum InhouseGameType {
  Plinko = 'plinko',
  Limbo = 'limbo',
  Dice = 'dice',
  Wheel = 'wheel',
  Mine = 'mines',
}

export enum GamePlayAction {
  Play,
  MakeSpin,
  MakeSpinWithFiat,
}

export const INHOUSE_GAMES = [
  {
    type: InhouseGameType.Plinko,
    name: 'Plinko',
  },
  {
    type: InhouseGameType.Limbo,
    name: 'Limbo',
  },
  {
    type: InhouseGameType.Dice,
    name: 'Dice',
  },
  {
    type: InhouseGameType.Wheel,
    name: 'Wheel',
  },
  {
    type: InhouseGameType.Mine,
    name: 'Mines',
  },
]

export class Game {
  code: string
  name: string
  providerCode: string
  thumbnail: string
  slug: string
  favoriteCount: number
  type: GameType
  isMaintenance: boolean
}

export interface GameDescription {
  description: string
  attributes: { label: string; value: string; isHighlight: boolean }[]
}

export type GameRatingInfo = [number, number, number, number, number]
export interface ResponsiveGameCollection {
  isFeature?: boolean
  itemCount?: number
  row?: number
  col?: number[] | number
  gap?: number[] | number
}

export interface GameSession {
  code: string
  gameUrl: string
}

export type GameDetail = Game & {
  categoryId: string
  merchantCode: string
  type: GameType
  isPlayDemo: boolean
}

export type InhouseGameDetails = GameDetail & {
  type: GameType.InHouse
  additionalData?: {
    inhouseGameType: InhouseGameType
  }
}

export class GameDemoSession {
  code: number
  gameDemoURL: string
}

export interface FeaturedGamesMap {
  wageringBonusGames: {
    [gameCode: string]: boolean
  }
  favoriteGames: {
    [gameCode: string]: boolean
  }
}
