import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import OpenEffect from 'components/OpenEffect'
import Text from 'components/Text'
import { ChainIdEnum, NETWORK_MAP } from 'config/constants/network'
import { PaymentMethodEnum } from 'config/constants/payment'
import { RouteConfig } from 'config/constants/route'
import { HUSD_TOKEN } from 'config/constants/tokens'
import { PaymentMethod, Token } from 'config/types'
import { useAnalytics } from 'hooks/useAnalytics'
import useAuthenticationModal from 'hooks/useAuthenticationModal'
import { useRouter } from 'hooks/useRouter'
import { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useAppSelector } from 'state'
import { useListNetworks, useTokenPaymentMethod, useValidateDepositToken } from 'state/app/hooks'
import { useAuth } from 'state/auth/hooks'
import { useTokenSelectedExcludeFiat } from 'state/session/hooks'
import styled from 'styled-components'
import theme from 'theme'
import { getCurrencyBalance } from 'utils/infura'
import GameInfoMessage from 'views/GameDetails/GameInfoMessage'
import HUSDInfo from '../../HUSDInfo'
import PaymentTokenSelect from '../../PaymentTokenSelect'
import WaitingDepositBonusList from '../WaitingDepositBonusList'
import DepositByContract from './DepositByContract'
import DepositByQRCode from './DepositByQRCode'
import { useWaitingDepositToActiveBonus } from './hooks'

const Deposit: React.FC<{ initialToken?: Token }> = ({ initialToken }) => {
  const [_, onDismiss] = useAuthenticationModal()
  const router = useRouter()
  const { t } = useTranslation()
  const { addMetaEvent } = useAnalytics()
  const { bonuses, handleSelectedBonus, selectedBonus, isSelectedBonusExpired } = useWaitingDepositToActiveBonus()

  const [isDepositing, setIsDepositing] = useState(false)
  const { isSigned } = useAuth()
  const playToken = useTokenSelectedExcludeFiat()

  const [selectedToken, setSelectedToken] = useState<Token>(initialToken || playToken)
  const wallet = useAppSelector((state) => state.auth.wallet)
  const address = wallet?.address
  const method = useTokenPaymentMethod(selectedToken)
  const networks = useListNetworks()

  useEffect(() => {
    if (!isSigned) return
    addMetaEvent('InitiateCheckout', {
      currency: initialToken
        ? `${initialToken.name}|${ChainIdEnum[initialToken.network]}`
        : `${playToken.name}|${ChainIdEnum[playToken.network]}`,
    })
  }, [isSigned])

  const fetchTokenBalanceFn = useCallback(
    async (token: Token) => {
      if (!address) return null
      const amount = await getCurrencyBalance(token, address)
      return amount.amount
    },
    [address],
  )

  const { isBlackList, isLogicLocked } = useValidateDepositToken(selectedToken)

  return (
    <StyledContainer id="modalpayment" openType="grow" duration={0.4}>
      <PaymentTokenSelect
        disabled={isDepositing}
        token={selectedToken}
        onTokenSelect={(token) => setSelectedToken(token)}
        networks={networks}
        fetchTokenBalanceFn={method === PaymentMethod.TransferToken ? null : fetchTokenBalanceFn}
        from={PaymentMethodEnum.Deposit}
      />

      {isBlackList ? (
        <GameInfoMessage
          variant="error"
          maxWidth="100% !important"
          my="12px"
          message={t('Deposit with {{tokenName}}({{network}}) has been suspended.', {
            tokenName: selectedToken.name,
            network: NETWORK_MAP[selectedToken.network].networkInfo.shortName,
          })}
        />
      ) : isLogicLocked ? (
        <GameInfoMessage
          variant="error"
          maxWidth="100% !important"
          my="12px"
          message={t(
            '{{tokenName}}({{network}}) deposit suspended for development. Please contact CS for assistance.',
            {
              tokenName: selectedToken.name,
              network: NETWORK_MAP[selectedToken.network].networkInfo.shortName,
            },
          )}
        />
      ) : selectedToken === HUSD_TOKEN ? (
        <HUSDInfo />
      ) : (
        <>
          <WaitingDepositBonusList
            mt="20px"
            disabled={isDepositing}
            selectedToken={selectedToken}
            bonuses={bonuses}
            handleSelectedBonus={handleSelectedBonus}
            selectedBonus={selectedBonus}
            isSelectedBonusExpired={isSelectedBonusExpired}
          />
          {method === PaymentMethod.TransferToken ? (
            <DepositByQRCode depositToken={selectedToken} />
          ) : (
            <DepositByContract
              setIsDepositing={setIsDepositing}
              isDepositing={isDepositing}
              depositToken={selectedToken}
              flex="1 1"
              handleSelectedBonus={handleSelectedBonus}
              selectedBonus={selectedBonus}
              isSelectedBonusExpired={isSelectedBonusExpired}
            />
          )}
          <Flex justifyContent="center" mt="32px">
            <Box
              id="link-querydeposit"
              color="textHighlight"
              display="block"
              margin="auto"
              onClick={() => {
                router.push(RouteConfig.QueryDeposit)
                onDismiss()
              }}
            >
              <Text
                small
                color="textTertiary"
                fontWeight={300}
                lineHeight="24px"
                style={{
                  letterSpacing: '-0.02em',
                  cursor: 'pointer',
                }}
              >
                <Trans>Deposit haven't arrived?</Trans>
                <span
                  style={{
                    color: theme.colors.primaryAlt,
                    fontWeight: 400,
                    marginLeft: '6px',
                  }}
                >
                  <Trans>Click here</Trans>
                </span>
              </Text>{' '}
            </Box>
          </Flex>
        </>
      )}
    </StyledContainer>
  )
}

const StyledContainer = styled(OpenEffect)`
  display: flex;
  flex-direction: column;
`

export default Deposit
